<template>
  <main class="history-page page">
    <BreadcrumbsComponent title="История театра" />
    <div class="container-padding">
      <div class="history-page__inner">
        <ImgComponent v-if="history && history.img" :img="history.img" class="history-page__img" />
        <h3 v-if="history && history.title" class="history-page__title">{{ history.title }}</h3>
        <span v-if="history && history.description">{{ history.description }}</span>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import HISTORY_PAGE from "gql/pages/HistoryPage.graphql";

export default {
  name: "HistoryPage",
  metaInfo: {
    title: "История театра",
  },
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: HISTORY_PAGE,
      })
      .then(({ data }) => {
        store.dispatch("history/save", data);
      });
  },
  computed: {
    history() {
      return this.$store.state.history.history;
    },
  },
  components: {
    ImgComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.history-page {

  &__inner {
    padding 50px 0 0
  }

  &__img {
    max-height 580px
    border-radius 10px
  }

  &__title {
    font-size 2rem
    margin-top 60px
  }
}
</style>
